import styled, {css} from 'styled-components'
import {device} from '../../../theme'

export const SectionTitleWrap = styled.div `
    display: flex;
    align-items: center;
    flex-direction:row;
    .arrow{
        width:35px;
        height:3px;
        display:block;
        background:${props => props.theme.colors.themeColor};
        margin:10px 20px;
    }
    @media ${device.small}{
        text-align:center;
        .arrow{
            margin:10px auto;
        }
        flex-direction:column;
    }
    text-align: ${props => props.align};
    margin-bottom: ${props => props.mb};
    ${props => props.responsive && css `
        ${props => props.responsive.medium && css `
            @media ${device.medium}{
                margin-bottom: ${props => props.responsive.medium.mb};
                text-align: ${props => props.responsive.medium.align};
            }
        `}
        ${props => props.responsive.small && css `
            @media ${device.small}{
                margin-bottom: ${props => props.responsive.small.mb};
                text-align: ${props => props.responsive.small.align};
            }
        `}
    `}
    h6{
        color: ${props => props.theme.colors.textColor};
        font-weight: 400;
        ${props => props.layout === 1 && css `
            margin-top: -5px;
            text-transform:lowercase;
            font-size: 14px;
            line-height: 1.43;
        `}
        ${props => props.layout === 2 && css `
            font-size: 18px;
        `}
    }
    h3{
        text-transform:capitalize;
        ${props => props.layout === 2 && css `
            margin-bottom: 20px;
        `}
        span{
            color: ${props => props.theme.colors.themeColor};
        }
       }
    }
`;